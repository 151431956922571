<template>
  <label
    :class="{
      'v-input--is-disabled': disabled,
      'eva-label': !disabled,
    }"
  >
    <slot>
      <span class="font-weight-medium">
        {{ label }}
      </span>
      <span
        class="grey--text d-block"
        v-if="subtitle"
      >
        {{ subtitle }}
      </span>
    </slot>
  </label>
</template>

<script>
export default {
  name: "EvaLabel",
  props: {
    label: {
      type: String,
      default: "",
    },
    subtitle: {
      type: [String, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

/* Should inherit from .theme--light.v-label */
.eva-label {
  color: rgba(0,0,0,.6);
}
</style>
