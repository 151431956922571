import store from "../../store"

// How to use this module :
// 1. Import evalmeeOnboarding, autoRedirectToOnboarding in your router
// 2. add `...evalmeeOnboarding` to your routes
// 3. add `autoRedirectToOnboarding(router)` in your router

const onboardingRouteName = "onboardingUser"

const evalmeeOnboarding = [
  {
    path: "/onboarding",
    name: onboardingRouteName,
    component: ()=>import("../../views/onboarding_user.vue"),
  },
]

const autoRedirectToOnboarding = (router) => {
  router.beforeEach((to, from, next) => {
    if(to.name === onboardingRouteName) return next()

    if(store.getters.currentUser?.onboarding_state) {
      redirectToOnboarding(next, to)
      return
    }

    store.dispatch("fetchCurrentUser")
      .then( () => redirectToOnboarding(next, to))
  })
}

const shouldRedirectToOnboarding = () => {
  return store.getters.currentUser?.onboarding_state !== "complete"
}

const redirectToOnboarding = (next, redirectTo = null) => {
  return shouldRedirectToOnboarding() ?
    next({ name: onboardingRouteName, query: { redirect: redirectTo.path } }) :
    next()
}

export {
  evalmeeOnboarding,
  autoRedirectToOnboarding
}

