<template>
  <div>
    <v-sheet
      v-for="(item, index) in tempFeatures"
      :key="item.name"
    >
      <eva-switch
        @change="updateUser"
        v-model="item.value"
        :label="item.title"
        :disabled="item.type === 'private' && !allowPrivateAndHidden"
        switch-on-right
        :description="withDescription ? item.description : null"
        hide-details
      />
      <div v-if="item.type === 'private' && !allowPrivateAndHidden">
        {{ $t("user.edit.features.invitationOnly") }}
        <a @click="showCrispChat(item.title)"> {{ $t("user.edit.features.askAccess") }} </a>
      </div>
      <v-divider
        v-if="index !== tempFeatures.length -1"
        class="mt-2"
      />
    </v-sheet>
  </div>
</template>
<script>
import EvaSwitch from "@/components/shared/inputs/eva-switch.vue"
import getFeatureMixin from "@/components/shared/get_feature_mixin"
import chatSupportHelpers from "@/helpers/chat_support_helpers"

export default {
  name: "BetaFeaturesList",
  components: { EvaSwitch },
  props: {
    user: {
      type: Object,
      required: true,
    },
    withDescription: {
      type: Boolean,
      default: true,
    },
    allowPrivateAndHidden: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [getFeatureMixin],
  emits: ["update:user"],
  data: () => ({
    tempFeatures: [],
  }),
  methods: {
    updateUser() {
      let user = { ...this.user }
      user.beta_features = this.tempFeatures
      this.$emit("update:user", user)
    },
    showCrispChat(featureName) {
      chatSupportHelpers.showCrispChat()
      chatSupportHelpers.prefillCrispMessage(this.$t("user.edit.features.supportChatAskAccessTo", { featureName }) )
      chatSupportHelpers.showCrispMessage(this.$t("live.help.supportChatDefaultMessage"))
    },
  },
  computed: {
    currentAppBetaFeatures() {
      const currentApp = this.user.last_app_used
      return this.user.beta_features.filter(bf => bf.app === currentApp).map(f => this.getFeature(f.name))
    },
  },
  mounted() {
    const filter = this.allowPrivateAndHidden ? f => true : f => f.type !== "hidden"
    this.tempFeatures = this.currentAppBetaFeatures.filter(filter)
  },
}
</script>


<style scoped>

</style>
