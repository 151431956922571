import userProfile from "../../views/user_profile.vue"

const evalmeeUserProfile = [
  {
    path: "/profile",
    name: "userProfile",
    component: userProfile,
  },
]

export {
  evalmeeUserProfile,
}

