<template>
  <v-app
    id="evalmee-app"
    :style="{background: $vuetify.theme.themes.light.background}"
  >
    <app-notifications />
    <v-main>
      <v-container>
        <v-btn
          icon
          to="/"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <edit-user-profile
          v-if="this.isEditing"
          @editCancelled="editCancelled()"
          @userEdited="userEdited()"
          :user="this.tempUser"
        />

        <show-user-profile
          v-else
          @toggleEditForm="toggleEditForm()"
          :user="tempUser"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import showUserProfile from "./user_profile/showUserProfile.vue"
import editUserProfile from "./user_profile/editUserProfile.vue"
import appNotifications from "../components/shared/app_notifications.vue"
import { mapGetters } from "vuex"

export default {
  name: "UserProfile",
  components: { showUserProfile, editUserProfile, appNotifications },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      isEditing: false,
      tempUser: {},
    }
  },
  methods: {
    toggleEditForm() {
      this.isEditing = true
    },
    userEdited() {
      this.isEditing = false
    },
    editCancelled() {
      this.copyUser()
      this.isEditing = false
    },
    copyUser() {
      this.tempUser = { ...this.currentUser }
    },
  },
  watch: {
    currentUser: {
      handler(user) {
        this.copyUser()
        if(user.id) return
        this.$router.push(
          { name: "userLogin", query: { redirect_to: window.location.href } })
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
