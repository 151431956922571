<template>
  <div class="container">
    <v-card
      class="mt-5"
      outlined
      flat
    >
      <v-card-title>{{ `${this.user.first_name} ${this.user.last_name}` }}</v-card-title>

      <v-list dense>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-school-outline</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ schoolsNames.join(", ") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon><v-icon>mdi-email-outline</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.user.email }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="toggleEditForm"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          {{ $t("user.edit_profile") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="user.teacher"
      class="mt-4"
      outlined
      flat
    >
      <v-card-title>
        {{ $t("user.edit.features.betaTitle") }}
      </v-card-title>
      <v-card-text>
        <beta-features-list
          :user="user"
          @update:user="saveUser"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import getFeatureMixin from "../../components/shared/get_feature_mixin"
import BetaFeaturesList from "@/views/user_profile/betaFeaturesList.vue"
import { useSchoolsUser } from "@/composables/useSchoolsUser"

export default {
  name: "showUserProfile.vue",
  components: { BetaFeaturesList },
  props: {
    user: Object,
  },
  mixins: [getFeatureMixin],
  data() {
    return {
      switch1: false,
      tempFeatures: [],
    }
  },
  methods: {
    ...mapActions(["updateCurrentUser", "newAppNotification"]),
    toggleEditForm() {
      this.$emit("toggleEditForm")
    },
    saveUser(user) {
      this.updateCurrentUser(user).then(
        this.newAppNotification({ message: this.$t("user.edit.successMessage"), type: "success" })
      )
    },
  },
  computed: {
    ...mapGetters(["currentSchoolsUsers"]),
    schoolsNames() {
      return this.currentSchoolsUsers.map((schoolsUser) => useSchoolsUser(schoolsUser).school.value?.name)
    },
  },
}
</script>

<style scoped>

</style>
