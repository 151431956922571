<template>
  <div>
    <v-form
      @submit.prevent="saveCurrentUser"
      v-model="valid"
      lazy-validation
      ref="form"
    >
      <v-card class="mt-5">
        <v-card-title>{{ $t("user.edit.personal_infos") }}</v-card-title>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="mutableUser.first_name"
                :label="$t('user.first_name')"
                :rules="[rules.required]"
                validate-on-blur
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="mutableUser.last_name"
                :label="$t('user.last_name')"
                :rules="[rules.required]"
                validate-on-blur
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card class="mt-5">
        <v-card-title>{{ $t("user.edit.password") }}</v-card-title>

        <v-list>
          <v-list-item>
            <v-list-item-content>{{ $t("user.edit.password_tips") }}</v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="mutableUser.password"
                :label="$t('user.edit.new_password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                counter
                validate-on-blur
              />
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="mutableUser.password_confirmation"
                :label="$t('user.edit.password_confirmation')"
                :rules="[rules.passwordMin, rules.passwordMin]"
                :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmation ? 'text' : 'password'"
                @click:append="showConfirmation = !showConfirmation"
                counter
                validate-on-blur
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card
        flat
        color="transparent"
      >
        <v-card-actions>
          <v-spacer />

          <eva-button
            @click="editCancelled"
            :label="$t('cancel')"
          />

          <eva-button
            primary
            type="submit"
            :label="$t('save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "editUserProfile.vue",
  props: ["user"],
  data() {
    return {
      mutableUser: this.user,
      valid: true,
      showPassword: false,
      showConfirmation: false,
      rules: {
        required: value => !!value || "Required.",
        passwordMin: () => !this.mutableUser.password || this.mutableUser.password_confirmation?.length >= 8 || this.$t("user.edit.passwordMin"),
        passwordMatch: () => (this.mutableUser.password === this.mutableUser.password_confirmation) || this.$t("user.edit.passwordMatch"),
      },
    }
  },
  methods: {
    ...mapActions(["updateCurrentUser", "newAppNotification"]),
    editCancelled() {
      this.$emit("editCancelled")
    },
    saveCurrentUser() {
      if (this.$refs.form.validate()) {
        this.updateCurrentUser(this.mutableUser).then(this.onFulfillment).catch(this.onRejection)
      }
    },
    onFulfillment() {
      this.newAppNotification({ message: this.$t("user.edit.successMessage"), type: "success" })
      this.$emit("userEdited")
    },
    onRejection() {
      this.newAppNotification({ message: this.$t("user.edit.error_message"), type: "error" })
    },
  },
}
</script>
